/*
 * @Author: wenxiujiang 
 * @Date: 2023-08-03 18:17:42 
 * @Last Modified by: wenxiujiang
 * @Last Modified time: 2023-08-07 20:30:27
 */

import Satisfaction from 'common/resource/satisfaction';

export default {
    state: {
        satisfactionMap: null
    },

    getters: {
        satisfactionMap: state => state.satisfactionMap
    },

    mutations: {
        saveSatisfaction(state, params) {
            if (params) {
                const {resolved, star, tags} = params;
                state.satisfactionMap = {
                    ...state.satisfactionMap,
                    [params.conversationId]: {resolved, star, tags}
                };

                return;
            }
        }
    },

    actions: {
        async fetchSatisfaction({commit}, {conversationId, userId}) {
            if (!conversationId || conversationId === -1 || !userId) return;

            const {data: {body}} = await Satisfaction.getEvaluationDetail({params: {conversationId, userId}, hideLoading: true});

            commit('saveSatisfaction', body);
        }
    }
};
