var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-config-provider",
    { attrs: { locale: _vm.AntLocaleMap[_vm.i18n.locale] } },
    [
      _c(
        "a-spin",
        {
          staticClass: "yqg-spin",
          attrs: { spinning: _vm.reqCount > 0 },
          scopedSlots: _vm._u(
            [
              _vm.fileUploadMap
                ? {
                    key: "indicator",
                    fn: function () {
                      return [
                        _c("a-progress", {
                          staticClass: "progress",
                          attrs: {
                            "stroke-linecap": "square",
                            percent: _vm.progress,
                            type: "circle",
                          },
                        }),
                      ]
                    },
                    proxy: true,
                  }
                : null,
            ],
            null,
            true
          ),
        },
        [_c("router-view")],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }