/*
 * @Author: zhijiezhang 
 * @Date: 2021-07-22 16:43:22 
 * @Last Modified by: suzilin
 * @Last Modified time: 2024-02-21 14:50:54
 */

import store from 'common/store';

import {STAGE} from './global';

// const gafParams = {
//     wechat_h5: {
//         defaultAppCode: 'customer_service',
//         defaultChannelCode: 'genius_app'
//     },
//     businessCode: 'gaf_service'
// };
// const bzbParams = {
//     app: {
//         defaultAppCode: 'customer_service',
//         defaultChannelCode: 'bzb_app'
//     },
//     wechat_h5: {
//         defaultAppCode: 'customer_service',
//         defaultChannelCode: 'bzb_wechat'
//     },
//     businessCode: 'ycs_service'
// };
// const defaultParams = {
//     app: {
//         defaultAppCode: 'wealth',
//         defaultChannelCode: 'wealth_app'
//     },
//     businessCode: 'customer_service',
// },

const testLocale = Object.fromEntries(new URL(location.href).searchParams.entries()).locale;

export const isGAF = STAGE.indexOf('gaf') > -1; // 国内 - 吉致

export const isBZB = STAGE.indexOf('bzb') > -1; // 国内 - 中关村(发布在customer-service仓)

export const isOverseas = /overseas|indo|mex|india|brazil|phi|sea|eu/i.test(STAGE); // 所有的海外环境

export const isIndo = /indo/i.test(STAGE) || testLocale === 'id'; // 海外 - 印尼

export const isMex = /mex/i.test(STAGE) || testLocale === 'es'; // 海外 - 墨西哥

export const isPhi = /phi/i.test(STAGE) || testLocale === 'en'; // 海外 - 菲律宾

export const isEu = /eu/i.test(STAGE) || testLocale === 'eu'; // 海外 - 欧洲波兰

export const isTh = /prod-sea/i.test(STAGE) || testLocale === 'th'; // 海外 - 泰国

export const isYchat = STAGE === 'prod' || STAGE === 'test' || STAGE === 'dev' || isOverseas;

export const isOnlyYchat = isYchat && !isOverseas;

export const isProd = /prod/i.test(STAGE);

export const isTest = /test/i.test(STAGE);

export const stageMaps = {
    gaf: ['prd-gaf', 'sit-gaf', 'test-gaf', 'uat-gaf'],
    ycs: ['feat-bzb', 'prod-bzb', 'test-bzb'],
    ychat: ['prod', 'test'],
    overseas: ['test-overseas', 'feat-overseas', 'prod-indo']
};

export const loanJumpUrl = ({
    test: 'https://appwebtest.yangqianguan.com',
    prod: 'https://appweb.yangqianguan.com'
})[STAGE];

const {getHostCond} = store.getters;

export default getHostCond;
