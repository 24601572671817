/*
 * @Author: zhijiezhang 
 * @Date: 2021-01-29 16 yuhaoyang 
 * @Last Modified by: wenxiujiang
 * @Last Modified time: 2023-11-29 16:03:03
 */

import {isGAF} from 'common/constant/host-cond';
import {pre} from 'common/util';

const useRem = true;

export default [
    {
        path: '/index',
        name: 'index',
        component: () => import('app/index'),
        meta: {
            navTitle: pre('index')
        }
    },
    {
        path: '/web-chat',
        name: 'webChat',
        component: () => import('app/web-chat')
    },
    {
        path: '/app-chat',
        name: 'appChat',
        component: () => import('app/app-chat')
    },

    {
        path: '/control-app-chat',
        name: 'controlAppChat',
        component: () => import('app/control-app-chat')
    },
    {
        path: '/genius-contract-status',
        name: 'geniusContractStatus',
        component: () => import('app/genius-contract-status')
    },
    {
        path: '/dealer',
        name: 'dealer',
        component: () => import('app/genius-contract-status/dealer-info'),
    },
    {
        path: '/identify',
        name: 'identify',
        component: () => import('app/genius-contract-status/identity-fail'),
    },
    {
        path: '/supplementary-contract',
        name: 'supplementaryContract',
        component: () => import('app/genius-contract-status/supplementary-contract'),
    },
    {
        path: '/satisfaction',
        name: 'satisfaction',
        component: () => import('app/satisfaction'),
        meta: {
            navTitle: pre('evaluation')
        }
    },
    // ychat 询前表单 for 微信客服
    {
        path: '/verify',
        name: 'verify',
        component: () => import('app/inquire-form'),
        meta: {
            navTitle: '询前表单'
        }
    },
    {
        path: '/call-satisfaction',
        name: 'callSatisfaction',
        component: () => import('app/call-satisfaction'),
        meta: {
            navTitle: '满意度评价',
            call: true
        }
    },
    {
        path: '/health-examination',
        name: 'healthExamination',
        component: () => import('app/health-examination'),
        meta: {
            navTitle: '健康检查'
        }
    },
    {
        path: '/leave-message',
        name: 'leaveMessage',
        component: () => import('app/leave-message'),
    },
    {
        path: '/l/:buttonIdParam/:signParam',
        name: 'leaveMessage',
        component: () => import('app/leave-message'),
    },
    // 服务记录短链
    {
        path: '/l/:signParam',
        name: 'leaveMessageShort',
        component: () => import('app/leave-message'),
    },
    {
        path: '/leave-message/additional',
        name: 'leaveMessageAdditional',
        component: () => import('app/leave-message/additional'),
    },
    {
        path: '/leave-message-detail',
        name: 'leaveMessageDetail',
        component: () => isGAF ? import('app/leave-message/detail-gaf') : import('app/leave-message/detail'),
        meta: {
            useRem: !isGAF
        }
    },
    {
        path: '/warm-prompt',
        name: 'warmPrompt',
        component: () => import('app/warm-prompt'),
        meta: {
            navTitle: '温馨提示'
        }
    },
    {
        // 海外邮件满意度评价
        path: '/overseas-email-satisfaction',
        name: 'overseasEmailSatisfaction',
        component: () => import('app/overseas/email-satisfaction')
    },
    {
        // 海外邮件满意度评价短链接
        path: '/es/:ticketId/:ticketNumber/:sign',
        name: 'overseasEmailSatisfactionShort',
        component: () => import('app/overseas/email-satisfaction')
    },
    {
        // 海外邮件满意度评价短链接 优化2期
        path: '/es/:ticketId/:sign',
        name: 'overseasEmailSatisfactionShortV2',
        component: () => import('app/overseas/email-satisfaction')
    },
    {
        path: '/overseas-help-center',
        name: 'overseasHelpCenter',
        component: () => import('app/overseas/help-center'),
        meta: {
            useRem
        }
    },
    {
        path: '/overseas-complaints-step',
        name: 'overseasComplaintsStep',
        component: () => import('app/overseas/help-center/complaints-step'),
        meta: {
            useRem
        }
    },
    {
        path: '/help-center',
        name: 'helpCenter',
        component: () => import('app/help-center'),
        meta: {
            navTitle: '客服中心',
            fontSize: 16
        }
    },
    {
        path: '/guide-wechat',
        name: 'guideWechat',
        component: () => import('app/help-center/guide-wechat'),
        meta: {
            navTitle: '联系客服',
            useRem
        }
    },
    {
        path: '/certificate-diagram',
        name: 'certificateDiagram',
        component: () => import('app/help-center/certificate')
    },
    // gaf - nps评分页面
    {
        path: '/nps',
        name: 'nps',
        component: () => import('app/satisfaction/nps'),
        meta: {
            navTitle: 'NPS评价'
        }
    },
    {
        path: '/',
        redirect: {name: 'index'}
    },
    {
        path: '*',
        redirect: {name: 'index'}
    }
];
