/*
 * @Author: suzilin
 * @Date: 2021-07-01 16:34:13
 * @Last Modified by: xiaoxueli
 * @Last Modified time: 2023-06-15 16:14:18
 */

export const ua = navigator.userAgent.toLowerCase();

export const isPC = !/ios|iphone|ipod|ipad|android/.test(ua);

export const isAndroid = /android/.test(ua);

export const isIOS = /iphone|ipad|ipod/.test(ua);

export const isWebview = /yqg|yqd|easycash|idn_fin|zebra/i.test(ua);

export const isEasycashApp = /easycash|idn_fin/.test(ua);

export const treeToArray = (tree, childKey = 'children') => {
    const arr = [];
    const expanded = datas => {
        if (datas && datas.length) {
            datas.forEach(item => {
                arr.push(item);
                expanded(item[childKey]);
            });
        }
    };

    expanded(tree);

    return arr;
};

export const transArrToMap = array => {
    const map = {};

    const traverse = node => {
        const {key} = node;

        map[key] = node;

        if (node.children?.length) {
            node.children.forEach(traverse);
        }
    };

    array.forEach(traverse);

    return map;
};
