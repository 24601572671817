/*
 * @Author: center 
 * @Date: 2018-03-26 17:19:21 
 * @Last Modified by: suzilin
 * @Last Modified time: 2024-05-13 21:09:08
 * @Last Modified by: suzilin
 * @Last Modified time: 2024-05-14 11:22:33
 */

import {isIOS, isAndroid, ua, isWebview} from '@cs/common/utils';

import initAndroidWebviewBridge from './lib/android-webview-javascript-bridge';
import initIOSWebviewBridge from './lib/ios-webview-javascript-bridge';

export const isZebra = /zebra/i.test(ua);

if (isIOS) {
    initIOSWebviewBridge();
} else {
    initAndroidWebviewBridge();
}

class YqgWebviewBridge {

    constructor() {
        const self = this;
        self.init();
        self.initReceiver();
    }

    init() {
        const self = this;

        if (isWebview) {
            // 初始化 Javascript Bridge
            if ((self.bridge = window.WebViewJavascriptBridge)) {
                self.bridge.init();
            } else {
                document.addEventListener('WebViewJavascriptBridgeReady', event => {
                    self.bridge = event.bridge;
                    self.bridge.init();
                }, false);
            }
        } else if (isAndroid && 'JavaScriptInterface' in window) {
            self.bridge = window.JavaScriptInterface;
        }
    }

    initReceiver() {
        const self = this;
        self.ReceivedEventMap = {};

        window.YqgWebMessager = {
            on(type, callback) {
                if (!self.ReceivedEventMap[type]) {
                    self.ReceivedEventMap[type] = [];
                }

                if (self.ReceivedEventMap[type].includes(callback)) return;
                self.ReceivedEventMap[type].push(callback);
            },

            off(type, callback) {
                if (!self.ReceivedEventMap[type]) return;
                if (!self.ReceivedEventMap[type].includes(callback)) return;
                const index = self.ReceivedEventMap[type].indexOf(callback);
                self.ReceivedEventMap[type].splice(index, 1);
            },

            send(type, payload) {
                if (!self.ReceivedEventMap[type]) return;
                self.ReceivedEventMap[type].forEach(callback => callback(payload));
            }
        };
    }

    callHandler(name, data, callback) {
        const self = this;
        const callbackWrapper = _data => (callback && callback(_data));
        let result;

        if (isWebview) {
            self.bridge.callHandler(name, data, callbackWrapper);
        } else if (isAndroid && 'JavaScriptInterface' in window) {
            result = self.bridge[name] && (data !== undefined ? self.bridge[name](data) : self.bridge[name]());
            callbackWrapper(result);
        } else {
            self.debug('尚未支持该操作系统');
        }
    }

    goBack() {
        if (isWebview) {
            this.callHandler('closeWebPage');
        } else {
            window.history.back();
        }
    }

    debug(text) {
        window.alert(text);
    }

}

export default new YqgWebviewBridge();
