/*
 * @Author: suzilin
 * @Date: 2022-08-02 15:58:02
 * @Last Modified by: wenxiujiang
 * @Last Modified time: 2023-12-08 17:11:44
 */

import Event from '@cs/common/crm/resource/event';
import {isIOS} from '@cs/common/utils';

import {isOnlyYchat, isOverseas} from 'common/constant/host-cond';
import webviewBridge, {isZebra} from 'common/core/webview-interface';

const BrandCountryMap = {
    // 目前只有印尼需要埋点，后面可以根据需求添加
    indo: 'IDN'
};

export default {
    uploadEvent: (info = {}, route, BRAND) => {
        const {
            elementType = 'Button',
            businessEvent = '', // web上传事件直接就是business_code不需要做事件映射转换
            eventValue = '', /// 控件的值，例如输入框中用户输入的内容
            eventType = 'C', // C:点击,S: 单选框,I: App文本输入,T: Web文本输入,P: 页面访问,M: 手动埋点
            tag = '',
            ovc = false,
            userId = null,
            ...other
        } = info;

        if (!BRAND) {
            // eslint-disable-next-line no-param-reassign
            BRAND = ovc ? 'indo' : 'CN';
        }

        // 海外app支持匿名登录-无customerId
        if ((userId && isOnlyYchat) || (ovc && isOverseas)) {
            const {name, path, fullPath} = route;
            const elementId = tag ? `${name}${tag}` : name;

            const eventData = {
                eventType,
                businessEvent,
                eventValue,
                componentId: `${elementId}:${BRAND}${path}`,
                eventParams: {
                    activity: `${BRAND}:${fullPath}`,
                    elementType,
                    elementId,
                    platform: isIOS ? 'ios' : 'android',
                    ...other
                },
                eventTime: Date.now(),
                platformType: 'WEB',
                sequenceNumber: 10
            };

            if (isZebra) {
                webviewBridge.callHandler('uploadEvent', JSON.stringify({
                    userId,
                    ...eventData
                }));
            } else {
                const uploadParams = {events: [eventData]};

                if (userId) {
                    uploadParams.userId = userId;
                }

                const params = {
                    hideLoading: true
                };

                if (isOverseas) {
                    params.headers = {
                        Country: BrandCountryMap[BRAND]
                    };
                }

                Event.uploadEvents(uploadParams, params);
            }
        }
    }
};
